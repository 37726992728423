import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? <Link to="/about">PL</Link> : <Link to="/about">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>About us</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>
              Hello, dear customers, we are the cleaning company "Ambulans magia
              czystości"!
            </h2>
            <p>
              The company "Ambulans magia czystości" holds an extremely
              important position on the market because it offers services
              necessary in everyday life. Cleaning furniture at home is a
              difficult task, especially if there is a lot of dirt in it.
              Therefore, it is worth using the services of professionals who
              know how to handle each type of upholstery and ensure proper
              cleaning.
            </p>
            <p>
              Our company specializes in professional cleaning of upholstery,
              including sofas, chairs, armchairs, beds, curtains, mattresses,
              carpets, carpets and car interiors. Our services are addressed to
              both individual clients and companies that need furniture cleaning
              services in offices, hotels, restaurants and other public places.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              The main goal of "Ambulans magia czystości" is to ensure maximum
              cleanliness and comfort in the premises of its clients. For this
              purpose, the company uses only high-quality, health-safe
              detergents and modern equipment that allows for efficient and fast
              work.
            </p>
            <p>
              Our company offers a wide range of services such as surface
              cleaning, stain removal, deodorization, disinfection, vacuuming
              and others. We tailor each service to the individual needs of the
              client to ensure the best possible effect.
            </p>
            <p>
              Cleaning upholstered furniture can be expensive, but our company
              offers competitive prices that vary depending on the size and type
              of furniture. Our prices are fair and transparent, which means
              customers don't have to worry about hidden costs.
            </p>
            <p>
              As a furniture cleaning company, we make every effort to ensure
              that our customers are clean, safe and satisfied with our
              services. The company "Ambulans magia czystości" is a reliable
              partner who always helps and ensures perfect cleanliness in your
              home or office.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <ButtomsLang />
        <WhatsApp />
        <AboutTable />
        <Footer />
      </div>
    </>
  );
}
export default About;
