import './Services.css';
import main1 from '../../../img/Services/clean_sofa8.jpg';
import main4 from '../../../img/Services/clean-matrass.jpg';
import main3 from '../../../img/Services/general.jpg';
import main2 from '../../../img/Services/clean_chair.jpg';
import main5 from '../../../img/Services/car4.jpg';
import main6 from '../../../img/Services/window5.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title main-services">
          <h1>
            <span className="main-services-h1span">
              Professionally Laundry{' '}
            </span>
            sofas, armchairs, mattresses, strollers, curtains, blinds, rugs,
            carpets, car interiors, window cleaning, general apartment cleaning…
          </h1>
          <p className="main-title-upper">
            <span>
              WE GUARANTEE A 100% REFUND IF THE CUSTOMER IS NOT SATISFIED WITH
              THE WASHING EFFECT!!!
            </span>
          </p>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Washing couches</h2>
            <p className="main-services-price">from 100 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Washing armchairs</h2>
            <p className="main-services-price">from 15 pln</p>
          </div>
          {/* <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Washing pillows</h2>
            <p className="main-services-price">from 5 pln</p>
          </div> */}
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>WASHING MATTRESS</h2>
            <p className="main-services-price">from 100 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main5} alt="photo5" />
            </div>
            <h2>car interiors</h2>
            <p className="main-services-price">from 200 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main6} alt="photo6" />
            </div>
            <h2>window washing</h2>
            <p className="main-services-price">from 40 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo6" />
            </div>
            <h2>
              general cleaning of apartments, offices, restaurants and buildings
            </h2>
            <p className="main-services-price">from 400 pln</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/ru/price">More details...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
