import './Banner.css';
import banner from '../../../img/banner/banner3.jpg';
import banner2 from '../../../img/banner/banner.png';
import banner3 from '../../../img/banner/banner4.jpg';
function Banner() {
  return (
    <div className="banner">
      <div className="banner__img banner-hidden">
        <img src={banner} alt="banner-img" />
      </div>
      <div className="banner__img banner-hidden">
        <img src={banner2} alt="banner-img " />
      </div>
      <div className="banner__img ">
        <img src={banner3} alt="banner-img" />
      </div>
    </div>
  );
}
export default Banner;
