import './Gallery.css';
import {
  ReactVideoPlayer,
  ReactVideoPlayer2,
  ReactVideoPlayer3,
  // ReactVideoPlayer4,
  // ReactVideoPlayer5,
  // ReactVideoPlayer6,
} from '../../../UI/ReactVideoPlayer2/ReactVideoPlayer2';

function VideoGallery() {
  return (
    <>
      <div className="main__third-services">
        <div className="main__gallery">
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer />
            </div>
            {/* <h2>Film reklamowy dla salon urody</h2> */}
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer2 />
            </div>
            {/* <h2>Nagranie budowy z drona</h2> */}
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer3 />
            </div>
            {/* <h2>Film reklamowy dla policjantów</h2> */}
          </div>
          {/* <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer4 />
            </div>
          
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer5 />
            </div>
          
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer6 />
            </div>
          
          </div> */}
        </div>

        {/* <div className="main__link-details">
          <a href="/gallery">Detale...</a>
        </div> */}
      </div>
    </>
  );
}
export default VideoGallery;
