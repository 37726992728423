import { Link } from 'react-router-dom';
import Img_phone from '../../../img/links/phone-ringing.png';

import './LinkPhone.css';

function Phone() {
  return (
    <div className="header__phone">
      <img
        title="phone"
        src={Img_phone}
        alt="phone"
        className="header__phone-img"
      />

      <div className="header__phone-links">
        <Link to={'tel:+48736334198'} className="">
          <h3 className="header-phone-item">+48 736334198</h3>
        </Link>
        <Link to={'tel:+48889859754'} className="">
          <h3 className="header-phone-item header-phone2">+48 889859754</h3>
        </Link>
      </div>
    </div>
  );
}
export function PhoneIcons() {
  return (
    <div className="header__phone">
      <div className="header__phone-links">
        <Link to={'tel:+48736334198'} className="">
          <h3 className="header-phone-item">+48 736334198</h3>
        </Link>
        <Link to={'tel:+48889859754'} className="">
          <h3 className="header-phone-item header-phone2">+48 889859754</h3>
        </Link>
      </div>
    </div>
  );
}
export function PhoneOnly() {
  return (
    <div className="header__phone-links">
      <Link to={'tel:+48736334198'} className="">
        <h3 className="header-phone-item">+48 736334198</h3>
      </Link>
      <Link to={'tel:+48889859754'} className="">
        <h3 className="header-phone-item header-phone2">+48 889859754</h3>
      </Link>
    </div>
  );
}

export default Phone;
