import { Link } from 'react-router-dom';
import Img_Facebook from '../../../img/links/facebook.png';
import Img_Facebook2 from '../../../img/links/facebook2.png';
import Img_Instagram from '../../../img/links/tiktok.png';
import Img_tiktok from '../../../img/links/tik-tok.png';

import './LinkSocial.css';

function LinkSocial() {
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://www.tiktok.com/@magiaczystosci1?_t=8mc343sTPwP&_r=1"
          title="Tiktok"
          alt="tiktok"
          target="_blank"
          className="header__instargam"
        >
          <img src={Img_Instagram} alt="tiktok" />
        </Link>
      </div>

      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61559662432234"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img src={Img_Facebook} alt="facebook" />
        </Link>
      </div>
    </div>
  );
}
export function LinkSocialfooter() {
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://www.tiktok.com/@magiaczystosci1?_t=8mc343sTPwP&_r=1"
          title="Tiktok"
          alt="tiktok"
          target="_blank"
          className="header__tiktok"
        >
          <img src={Img_tiktok} alt="tiktok" />
        </Link>
      </div>

      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61559662432234"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook2"
        >
          <img src={Img_Facebook2} alt="facebook" />
        </Link>
      </div>
    </div>
  );
}
export default LinkSocial;
