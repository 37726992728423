import Articles from '../Articles/Article';
import Important from '../Important/Important';
import Services from '../Services/Services';
import TitleGallary from '../Gallary/SWGallary';
import SwiperVideoGallery from '../../../components/2_Main/SwiperVideoGallery/SwiperVideoGallery';
import Banner from '../Banner/Banner';
import './Main.css';

function Main() {
  return (
    <main>
      <div>
        <Banner />
      </div>
      <div className="main-second main-first">
        <div className="container main-container">
          <Services />
        </div>
      </div>
      <div className="main-first main-important">
        <div className="container main__important">
          <Important />
        </div>
      </div>
      <div className="main-first main-important">
        <div className="container main__important">
          <TitleGallary />
        </div>
      </div>
      <div className="main-first main-gallery">
        <div className="container main__important">
          <SwiperVideoGallery />
        </div>
      </div>
      <div className="main-second main-first">
        <div className="container main-container">
          <Articles />
        </div>
      </div>
    </main>
  );
}
export default Main;
